import React, { useRef, useEffect, useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import Image from "../Image/Image";

import c from "../Layout/Layout.module.css";
import { getPublicReports } from "../../service/api";
const MenuList = React.lazy(() => import("../MenuList/MenuList"));

const headerLink =
  process.env.REACT_APP_HEADER_LOGO_URL ||
  "https://jabil.sharepoint.com/sites/DMSAnalyticsPortal";

function MainPage() {
  let location = useLocation();
  const iframeContainer = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchReports() {
      const res = await getPublicReports();
      setData(() => ({
        pages: res.filter((item) => item.type === "PAGE"),
        reports: res,
        formattedList: {
          pages: {
            title: "Reports & Dashboards",
            listData: res
              .filter((item) => item.type === "PAGE")
              .filter((item) => !item.url),
          },
          helpTutorial: {
            title: "Help & Support",
            listData: res
              .filter((item) => item.type === "PAGE")
              .filter((item) => !!item.url),
          },
        },
        routes: res
          .filter((item) => item.type === "PAGE")
          .filter((item) => !item.url)
          .map((page) => ({
            label: page.label,
            key: page.key,
            path: `${page.label.toLowerCase().replaceAll(" ", "-")}`,
            type: page.type,
            pageID: page.pageID,
          })),
        formatList: res
          .filter((item) => item.type === "PAGE" && !item.url)
          .map((page) => ({
            ...page,
            path: `/${page.label.toLowerCase().replaceAll(" ", "-")}`,
            sections: res
              .filter(
                (section) =>
                  section.pageID === page.pageID && section.type === "SECTION"
              )
              .map((section) => {
                return {
                  ...section,
                  path: `/${page.label
                    .toLowerCase()
                    .replaceAll(" ", "-")}/${section.label
                    .toLowerCase()
                    .replaceAll(" ", "-")}`,
                  items: res
                    .filter(
                      (item) =>
                        item.sectionID === section.sectionID &&
                        item.type === "ITEM" &&
                        item.pageID === section.pageID
                    )
                    .map((item) => ({
                      ...item,
                      subitems: res
                        .filter(
                          (subitem) =>
                            subitem.sectionID === section.sectionID &&
                            subitem.type === "SUB_ITEM" &&
                            subitem.pageID === section.pageID &&
                            subitem.itemID === item.itemID
                        )
                        .map((subitem) => ({
                          ...subitem,
                          secondSubitems: res
                            .filter(
                              (secondSubitem) =>
                                secondSubitem.sectionID === section.sectionID &&
                                secondSubitem.type === "SECOND_SUB_ITEM" &&
                                secondSubitem.pageID === section.pageID &&
                                secondSubitem.itemID === item.itemID &&
                                secondSubitem.subitemID === subitem.subitemID
                            )
                            .map((secondSubitem) => ({
                              ...secondSubitem,
                              thirdSubitems: res.filter(
                                (thirdSubitem) =>
                                  thirdSubitem.sectionID ===
                                    section.sectionID &&
                                  thirdSubitem.type === "THIRD_SUB_ITEM" &&
                                  thirdSubitem.pageID === section.pageID &&
                                  thirdSubitem.itemID === item.itemID &&
                                  thirdSubitem.subitemID ===
                                    subitem.subitemID &&
                                  thirdSubitem.secondSubitemID ===
                                    secondSubitem.secondSubitemID
                              ),
                            })),
                        })),
                    })),
                };
              }),
          })),
      }));
    }

    fetchReports();
  }, []);

  return (
    <Suspense
      fallback={
        <Spin
          style={{ alignSelf: "center", flex: "1 1 auto" }}
          tip="Loading"
          size="large"
        ></Spin>
      }
    >
      <main className={c.LayoutMain}>
        <aside className={c.LayoutColLeft}>
          {/* <Suspense
            fallback={
              <Spin
                style={{ alignSelf: "center", flex: "1 1 auto" }}
                tip="Loading"
                size="large"
              ></Spin>
            }
          > */}
          {data && (
            <MenuList
              data={data}
              location={location}
              iframeContainer={iframeContainer}
            />
          )}
          {/* </Suspense> */}
          <a className={c.BackButton} href={headerLink}>
            <span className="material-symbols-outlined">arrow_back</span> Go
            Back
          </a>
        </aside>

        <div className={c.LayoutColRight} ref={iframeContainer}>
          <Image
            title={location.pathname}
            src="https://media.akamai.odsp.cdn.office.net/eastus1-mediap.svc.ms/transform/thumbnail?provider=url&inputFormat=jpg&docid=https%3A%2F%2Fcdn.hubblecontent.osi.office.net%2Fm365content%2Fpublish%2Fde36f3ca-997f-4aad-a044-51caf6f03375%2F1225909157.jpg&w=960"
          />
        </div>
      </main>
    </Suspense>
  );
}

export default MainPage;
