import React, { useEffect, useState } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import { validAuth } from "../../service/api";

function RequireAuth({ children }) {
  const { user, signout, signin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    validAuth()
      .then((res) => {
        localStorage.setItem("user", true);
      })
      .catch((err) => {
        localStorage.setItem("user", false);
        navigate("/login");
      });
  }, []);

  // if (!user || false) {

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;
