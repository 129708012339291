import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = localStorage.getItem("access_token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export const getPublicReports = () =>
  axiosInstance.get("/reports").then((res) => res.data);

export const getReports = () =>
  axiosInstance.get("/reports/all").then((res) => res.data);

export const getByKey = ({ key }) =>
  axiosInstance.get(`/reports/${key}`).then((res) => res.data);

export const create = ({ data }) => {
  return axiosInstance.post("/reports", data).then((res) => res.data);
};

export const update = ({ data, key }) =>
  axiosInstance.patch(`/reports/${key}`, data).then((res) => res.data);

export const deleteReport = ({ key }) =>
  axiosInstance.delete(`/reports/${key}`).then((res) => res.data);

export const login = ({ email, password }) =>
  axiosInstance
    .post(`/auth/login`, { email, password })
    .then((res) => res.data);

export const logout = () =>
  axiosInstance.get(`/auth/logout`).then((res) => res.data);

export const deleteUser = ({ email }) =>
  axiosInstance.delete(`/auth/delete/${email}`).then((res) => res.data);

export const createUser = ({ data }) => {
  return axiosInstance.post("/auth/create", data).then((res) => res.data);
};

export const updateUser = ({ data }) =>
  axiosInstance.patch(`/auth/change-password`, data).then((res) => res.data);

export const getUsers = () =>
  axiosInstance.get("/auth/all").then((res) => res.data);

export const validAuth = () =>
  axiosInstance.get("/auth/valid").then((res) => res.data);

// Analytics
export const getAnalytics = () =>
  axiosInstance.get("/analytics").then((res) => res.data);

export const createAnalytics =
  () =>
  ({ data }) => {
    return axiosInstance.post("/analytics", data).then((res) => res.data);
  };

export const updateAnalytics = ({ data, key }) =>
  axiosInstance.patch(`/analytics/${key}`, data).then((res) => res.data);

export const deleteAnalytics = ({ key }) =>
  axiosInstance.delete(`/analytics/${key}`).then((res) => res.data);
