import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  List,
  Typography,
  Tabs,
  Button,
  Layout,
  Popconfirm,
} from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { getAnalytics, updateAnalytics } from "../../service/api";

const { Title } = Typography;
const { Content } = Layout;

function AnalyticsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab] = useState(
    location.pathname === "/analytics"
      ? "1"
      : location.pathname === "/analytics/pages"
      ? "1"
      : "2"
  );
  const [data, setData] = useState([]);
  const [type, setType] = useState(
    location.pathname.search("pages") > 0 ? "PAGE" : "REPORT"
  );

  useEffect(() => {
    if (location.pathname === "/analytics") {
      navigate("/analytics/pages");
    }

    const type =
      location.pathname === "/analytics"
        ? "PAGE"
        : location.pathname.search("pages") > 0
        ? "PAGE"
        : "REPORT";

    getAnalytics()
      .then((res) => {
        setData(() => res.filter((item) => item.type === type));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleResetCount = (key) => {
    updateAnalytics({ data: { count: 0 }, key })
      .then((res) => {
        setIsLoading(true);
        getAnalytics()
          .then((res) => {
            setData(() => res.filter((item) => item.type === type));
          })
          .catch((error) => {})
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {});
  };

  const onChangeTab = (key) => {
    setData([]);
    if (key === "1") {
      setIsLoading(true);
      navigate("/analytics/pages");
      setType("PAGE");
      getAnalytics()
        .then((res) => {
          setData(() => res.filter((items) => items.type === "PAGE"));
          setIsLoading(false);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (key === "2") {
      setIsLoading(true);
      navigate("/analytics/reports");
      setType("REPORT");
      getAnalytics()
        .then((res) => {
          setData(() => res.filter((items) => items.type === "REPORT"));
          setIsLoading(false);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Content style={{ padding: "1rem", overflowY: "scroll" }}>
      <Row
        justify="center"
        align="top"
        style={{ marginTop: "1rem", overflow: "scroll" }}
      >
        <Col span={18}>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={onChangeTab}
            items={[
              {
                label: `Pages`,
                key: "1",
                children: (
                  <List
                    loading={{
                      spinning: isLoading,
                      tip: "Loading...",
                      size: "default",
                    }}
                    style={{ minHeight: "237px" }}
                    bordered={true}
                    header={
                      <>
                        <Title level={3}>Analytics - Pages</Title>
                      </>
                    }
                    dataSource={data.sort((a, b) => b.count - a.count)}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Popconfirm
                            title="Are you sure you want to reset count?"
                            onConfirm={() => handleResetCount(item.key)}
                            okText="Confirm"
                            cancelText="Cancel"
                          >
                            <Button shape="circle" icon={<RedoOutlined />} />
                          </Popconfirm>,
                        ]}
                      >
                        <List.Item.Meta title={item.label} />
                        <div>{item.count}</div>
                      </List.Item>
                    )}
                  />
                ),
              },
              {
                label: `Reports`,
                key: "2",
                children: (
                  <List
                    loading={isLoading}
                    bordered={true}
                    header={
                      <>
                        <Title level={3}>Analytics - Reports</Title>
                      </>
                    }
                    dataSource={data.sort((a, b) => b.count - a.count)}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Popconfirm
                            title="Are you sure you want to reset count?"
                            onConfirm={() => handleResetCount(item.key)}
                            okText="Confirm"
                            cancelText="Cancel"
                          >
                            <Button shape="circle" icon={<RedoOutlined />} />
                          </Popconfirm>,
                        ]}
                      >
                        <List.Item.Meta title={item.label} />
                        <div>{item.count}</div>
                      </List.Item>
                    )}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default AnalyticsPage;
