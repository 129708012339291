import React, { useState, useEffect, useCallback } from "react";
import { Space, Table, Button as AntButton, Tooltip, message } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import ModalForm from "../ModalForm/ModalForm";
import ActionButtons from "../ActionButtons/ActionButton";

import { PlusOutlined } from "@ant-design/icons";

import { getReports, deleteReport, update, create } from "../../service/api";

import "antd/dist/antd.css";

const columns = [
  {
    title: "Label",
    dataIndex: "label",
    key: "label",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "12%",
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "20%",
    key: "action",
  },
];

const getLabel = (type) => {
  switch (type) {
    case "SECTION":
      return "Section";
    case "ITEM":
      return "Item";
    case "SUB_ITEM":
      return "Sub item";
    case "SECOND_SUB_ITEM":
      return "Second Subitem";
    case "THIRD_SUB_ITEM":
      return "Third Subitem";
    default:
      return "Item";
  }
};

function AdminPage() {
  const navigate = useNavigate();
  const [reports, setReports] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ type: "SUB_ITEM", key: "" });
  const [selectedPage, setSelectedPage] = useState("Supply Chain");

  useEffect(() => {
    async function fetchReports() {
      try {
        const data = await getReports();
        setReports(data);
      } catch (error) {
        message.error(error);
      }
    }
    fetchReports();
  }, []);

  const handleOk = useCallback(async ({ form, key, type }) => {
    try {
      if (type === "CREATE_NEW") {
        const newItemData = form.getFieldsValue();
        const response = await create({ data: newItemData });

        message.success(response.message);

        await form.resetFields();

        setIsModalOpen(false);

        const data = await getReports();
        setReports(data);
      } else {
        const getUpdatedData = form.getFieldsValue();
        const response = await update({ data: getUpdatedData, key: key });

        message.success(response.message);

        await form.resetFields();

        setIsModalOpen(false);

        const data = await getReports();
        setReports(data);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }, []);

  const handleDeleteItem = async (key) => {
    try {
      setIsLoading(true);
      const response = await deleteReport({ key });

      message.success(response);

      const data = await getReports();
      setReports(data);
    } catch (error) {
      message.error(error);
    }
  };

  const handleCancel = useCallback((form) => {
    form.resetFields();
    setIsModalOpen(false);
    setModalData({ type: "SUB_ITEM", key: "" });
  }, []);

  const onFinish = useCallback((values) => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  const handlerChangePage = useCallback(
    (e) => {
      if (e.target.getAttribute("data-label") === selectedPage) return;
      navigate(
        `/editor/${e.target
          .getAttribute("data-label")
          .toLowerCase()
          .replaceAll(" ", "-")}`
      );
      setSelectedPage(e.target.getAttribute("data-label"));
      setIsLoading(true);
    },
    [selectedPage]
  );

  const createForm = () => {
    setModalData(
      { type: "CREATE_NEW", key: "", reports: reports },
      setIsModalOpen(true)
    );
  };

  const removePage = async () => {
    try {
      setIsLoading(true);
      const response = await deleteReport({
        key: reports.find((item) => item.label === selectedPage).key,
      });

      message.success(response);

      const data = await getReports();
      setReports(data);
      setSelectedPage(reports.find((item) => item.type === "PAGE").label);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (reports) {
      setData(
        () =>
          reports
            .filter(
              (item) =>
                item.type === "SECTION" &&
                item.pageID ===
                  reports.find((item) => item.label === selectedPage).pageID
            )
            .sort((a, b) => a.order - b.order)
            .map((section) => ({
              ...section,
              type: getLabel(section.type),
              action: (
                <ActionButtons
                  onDelete={() => handleDeleteItem(section.key)}
                  onEdit={showModal({
                    type: section.type,
                    key: section.key,
                    openModal: true,
                  })}
                />
              ),
              children: !reports.filter(
                (items) =>
                  items.type === "ITEM" &&
                  items.pageID === section.pageID &&
                  items.sectionID === section.sectionID
              ).length
                ? null
                : reports
                    .filter(
                      (items) =>
                        items.type === "ITEM" &&
                        items.pageID === section.pageID &&
                        items.sectionID === section.sectionID
                    )
                    .sort((a, b) => a.order - b.order)
                    .map((item) => {
                      if (item.url)
                        return {
                          ...item,
                          type: getLabel(item.type),
                          action: (
                            <ActionButtons
                              onDelete={() => handleDeleteItem(item.key)}
                              onEdit={showModal({
                                type: item.type,
                                key: item.key,
                                openModal: true,
                              })}
                            />
                          ),
                        };
                      return {
                        ...item,
                        type: getLabel(item.type),
                        action: (
                          <ActionButtons
                            onDelete={() => handleDeleteItem(item.key)}
                            onEdit={showModal({
                              type: item.type,
                              key: item.key,
                              openModal: true,
                            })}
                          />
                        ),
                        children: !reports.filter(
                          (getSubItem) =>
                            getSubItem.type === "SUB_ITEM" &&
                            getSubItem.pageID === item.pageID &&
                            getSubItem.sectionID === item.sectionID &&
                            getSubItem.itemID === item.itemID
                        ).length
                          ? null
                          : reports
                              .filter(
                                (getSubItem) =>
                                  getSubItem.type === "SUB_ITEM" &&
                                  getSubItem.pageID === item.pageID &&
                                  getSubItem.sectionID === item.sectionID &&
                                  getSubItem.itemID === item.itemID
                              )
                              .sort((a, b) => a.order - b.order)
                              .map((subitem) => {
                                return {
                                  ...subitem,
                                  type: getLabel(subitem.type),
                                  action: (
                                    <ActionButtons
                                      onDelete={() =>
                                        handleDeleteItem(subitem.key)
                                      }
                                      onEdit={showModal({
                                        type: subitem.type,
                                        key: subitem.key,
                                        openModal: true,
                                      })}
                                    />
                                  ),
                                  children: !reports.filter(
                                    (getSecondSubItem) =>
                                      getSecondSubItem.type ===
                                        "SECOND_SUB_ITEM" &&
                                      getSecondSubItem.pageID === item.pageID &&
                                      getSecondSubItem.sectionID ===
                                        item.sectionID &&
                                      getSecondSubItem.itemID === item.itemID &&
                                      getSecondSubItem.subitemID ===
                                        subitem.subitemID
                                  ).length
                                    ? null
                                    : reports
                                        .filter(
                                          (getSubItem) =>
                                            getSubItem.type ===
                                              "SECOND_SUB_ITEM" &&
                                            getSubItem.pageID === item.pageID &&
                                            getSubItem.sectionID ===
                                              item.sectionID &&
                                            getSubItem.itemID === item.itemID &&
                                            getSubItem.subitemID ===
                                              subitem.subitemID
                                        )
                                        .sort((a, b) => a.order - b.order)
                                        .map((secondSubitem) => {
                                          return {
                                            ...secondSubitem,
                                            type: getLabel(secondSubitem.type),
                                            action: (
                                              <ActionButtons
                                                onDelete={() =>
                                                  handleDeleteItem(
                                                    secondSubitem.key
                                                  )
                                                }
                                                onEdit={showModal({
                                                  type: secondSubitem.type,
                                                  key: secondSubitem.key,
                                                  openModal: true,
                                                })}
                                              />
                                            ),
                                            children: !reports.filter(
                                              (getThirdSubitem) =>
                                                getThirdSubitem.type ===
                                                  "THIRD_SUB_ITEM" &&
                                                getThirdSubitem.pageID ===
                                                  item.pageID &&
                                                getThirdSubitem.sectionID ===
                                                  item.sectionID &&
                                                getThirdSubitem.itemID ===
                                                  item.itemID &&
                                                getThirdSubitem.subitemID ===
                                                  subitem.subitemID &&
                                                getThirdSubitem.secondSubitemID ===
                                                  secondSubitem.secondSubitemID
                                            ).length
                                              ? null
                                              : reports
                                                  .filter(
                                                    (getThirdSubitem) =>
                                                      getThirdSubitem.type ===
                                                        "THIRD_SUB_ITEM" &&
                                                      getThirdSubitem.pageID ===
                                                        item.pageID &&
                                                      getThirdSubitem.sectionID ===
                                                        item.sectionID &&
                                                      getThirdSubitem.itemID ===
                                                        item.itemID &&
                                                      getThirdSubitem.subitemID ===
                                                        subitem.subitemID &&
                                                      getThirdSubitem.secondSubitemID ===
                                                        secondSubitem.secondSubitemID
                                                  )
                                                  .sort(
                                                    (a, b) => a.order - b.order
                                                  )
                                                  .map((secondSubitem) => {
                                                    return {
                                                      ...secondSubitem,
                                                      type: getLabel(
                                                        secondSubitem.type
                                                      ),
                                                      action: (
                                                        <ActionButtons
                                                          onDelete={() =>
                                                            handleDeleteItem(
                                                              secondSubitem.key
                                                            )
                                                          }
                                                          onEdit={showModal({
                                                            type: secondSubitem.type,
                                                            key: secondSubitem.key,
                                                            openModal: true,
                                                          })}
                                                        />
                                                      ),
                                                    };
                                                  }),
                                          };
                                        }),
                                };
                              }),
                      };
                    }),
            })),
        setIsLoading(false)
      );
    }
  }, [reports, selectedPage]);

  const showModal = useCallback(
    ({ type, key, openModal }) => {
      return () => {
        setModalData(
          () => ({ type, key, reports, filteredData: data }),
          setIsModalOpen(openModal)
        );
      };
    },
    [data, reports]
  );

  return (
    <main style={{ padding: "1rem", overflowY: "scroll" }}>
      <Space
        align="center"
        style={{
          marginBottom: 16,
        }}
      >
        <h3>Pages: </h3>
        {reports !== null &&
          reports
            .filter((item) => item.type === "PAGE" && !item.url)
            .map((page) => {
              return (
                <Button
                  key={page.id}
                  label={page.label}
                  data-label={page.label}
                  data-type={page.pageID}
                  handler={handlerChangePage}
                />
              );
            })}
      </Space>
      <Table
        title={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {selectedPage}
            <Tooltip placement="top" title="Remove current page">
              <AntButton type="default" onClick={removePage}>
                Remove page
              </AntButton>
            </Tooltip>
            <Tooltip
              placement="left"
              title="Add new sub-item, item, section or page"
            >
              <AntButton
                onClick={createForm}
                shape="circle"
                icon={<PlusOutlined />}
              />
            </Tooltip>
          </div>
        )}
        pagination={{
          position: ["none", "none"],
        }}
        columns={columns}
        bordered
        loading={isLoading}
        dataSource={data}
      />

      {reports && !isLoading && (
        <ModalForm
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          modalData={modalData}
          data={data}
          onOk={handleOk}
          onFinish={onFinish}
        />
      )}
    </main>
  );
}

export default AdminPage;
