import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Select,
  Input,
  InputNumber,
  Checkbox,
  Descriptions,
  Typography,
  Divider,
} from "antd";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getPageTitle } from "../../helpers/getModalFormTitle";
import { getByKey, getReports } from "../../service/api";
import "./ModalForm.css";

const { Text } = Typography;

const { useForm } = Form;

const getItemLabel = ({ key, value, selectFormItem }) => {
  switch (key) {
    case "pageID":
    case "sectionID":
    case "itemID":
    case "subitemID":
    case "secondSubitemID":
      return selectFormItem[key].find((item) => item.value === value).label;
    default:
      return value;
  }
};

function ModalForm({
  isModalOpen = false,
  onFinish = () => {},
  handleCancel = () => {},
  modalData,
  data: prevData,
  onOk = () => {},
}) {
  const [form] = useForm();
  const [title, setTitle] = useState("Edit Report or Section");
  const [data, setData] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const typeValue = Form.useWatch("type", form);
  const pageIDValue = Form.useWatch("pageID", form);
  const sectionIDValue = Form.useWatch("sectionID", form);
  const itemIDValue = Form.useWatch("itemID", form);
  const subitemIDValue = Form.useWatch("subitemID", form);
  const [itemHaveChild, setItemHaveChild] = useState(false);
  const [selectFormItem, setSelectFormItem] = useState({
    pageID: [],
    sectionID: [],
    itemID: [],
    subitemID: [],
    secondSubitemID: [],
  });

  useEffect(() => {
    if (!isModalOpen) {
      setInitialValues({});
    }
    return () => form.resetFields();
  }, [isModalOpen]);

  useEffect(() => {
    if (modalData?.type === "CREATE_NEW") {
      async function getItemData() {
        const response = await getReports();
        setData(response);
      }
      getItemData();
    }

    if (modalData?.key) {
      async function getItemData() {
        const response = await getByKey({ key: modalData.key });
        setData(response[0]);
      }
      getItemData();
    }

    return () => {
      form.resetFields();
      setSelectFormItem({
        pageID: [],
        sectionID: [],
        itemID: [],
        subitemID: [],
        secondSubitemID: [],
      });
      setInitialValues(null);
      setData(null);
    };
  }, [modalData, form]);

  useEffect(() => {
    if (modalData) {
      setTitle(getPageTitle(modalData.type));
    }
  }, [modalData]);

  function checkChildren() {
    let result;

    prevData.forEach((section) => {
      if (section.key === data.key && section?.children) {
        result = true;
        return;
      }
      section?.children &&
        section?.children.forEach((item) => {
          if (item.key === data.key && item?.children) {
            result = true;
            return;
          }
          item?.children &&
            item?.children.forEach((subitem) => {
              if (subitem.key === data.key && subitem?.children) {
                result = true;
                return;
              }
              subitem?.children &&
                subitem?.children.forEach((secondSubitem) => {
                  if (
                    secondSubitem.key === data.key &&
                    secondSubitem?.children
                  ) {
                    result = true;
                    return;
                  }
                });
            });
        });
    });

    return result;
  }

  useEffect(() => {
    if (data && modalData?.reports && modalData?.type !== "CREATE_NEW") {
      setSelectFormItem({
        pageID: modalData.reports
          .filter((pages) => pages.type === "PAGE" && !pages.url)
          .map((page) => ({
            value: page.pageID,
            label: page.label,
          })),
        sectionID: modalData.reports
          .filter((sections) => sections.type === "SECTION")
          .map((section) => ({
            pageID: section.pageID,
            value: section.sectionID,
            label: section.label,
            url: section.url,
            iframe: section.iframe,
          })),
        itemID: modalData.reports
          .filter((items) => items.type === "ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            value: item.itemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
        subitemID: modalData.reports
          .filter((items) => items.type === "SUB_ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            itemID: item.itemID,
            subitemID: item.subitemID,
            value: item.subitemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
        secondSubitemID: modalData.reports
          .filter((items) => items.type === "SECOND_SUB_ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            itemID: item.itemID,
            subitemID: item.subitemID,
            secondSubitemID: item.secondSubitemID,
            value: item.secondSubitemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
      });
      setInitialValues(() => {
        return { ...data, haveChild: itemHaveChild || checkChildren() };
      });
    }

    if (data && modalData?.reports && modalData?.type === "CREATE_NEW") {
      setSelectFormItem({
        pageID: data
          .filter((pages) => pages.type === "PAGE" && !pages.url)
          .map((page) => ({
            value: page.pageID,
            label: page.label,
          })),
        sectionID: data
          .filter((sections) => sections.type === "SECTION")
          .map((section) => ({
            pageID: section.pageID,
            value: section.sectionID,
            label: section.label,
            url: section.url,
            iframe: section.iframe,
          })),
        itemID: data
          .filter((items) => items.type === "ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            itemID: item.itemID,
            value: item.itemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
        subitemID: data
          .filter((subitems) => subitems.type === "SUB_ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            subitemID: item.subitemID,
            itemID: item.itemID,
            value: item.subitemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
        secondSubitemID: data
          .filter((secondSubitems) => secondSubitems.type === "SECOND_SUB_ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            itemID: item.itemID,
            subitemID: item.subitemID,
            secondSubitemID: item.secondSubitemID,
            value: item.secondSubitemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
        thirdSubitemID: data
          .filter((thirdSubitems) => thirdSubitems.type === "THIRD_SUB_ITEM")
          .map((item) => ({
            sectionID: item.sectionID,
            pageID: item.pageID,
            itemID: item.itemID,
            subitemID: item.subitemID,
            secondSubitemID: item.secondSubitemID,
            value: item.secondSubitemID,
            label: item.label,
            url: item.url,
            iframe: item.iframe,
          })),
      });

      setInitialValues({});
    }
  }, [data, modalData]);

  if (!initialValues) return null;

  const handleOnFinish = (values) => {
    return onFinish(values);
  };

  const getConfirmContent = () => {
    const finishValuesKeys = Object.keys(form.getFieldsValue());
    const finishValues = form.getFieldsValue();

    if (modalData.type === "CREATE_NEW") {
      return (
        <>
          <Divider />
          <Descriptions title="What created" column={1}>
            {finishValues.url && finishValues.iframe && (
              <Descriptions.Item>
                <Text type="danger">
                  This item will work as a redirect, it will not open in an
                  iframe!
                </Text>
              </Descriptions.Item>
            )}
            {finishValuesKeys.map((key) => {
              if (!finishValues[key]) return null;

              return (
                <Descriptions.Item label={key}>
                  {getItemLabel({
                    key,
                    value: finishValues[key],
                    selectFormItem,
                  })}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </>
      );
    }

    return (
      <>
        <Divider />
        <Descriptions title="You change field to" column={1}>
          {finishValues.url && finishValues.iframe && (
            <Descriptions.Item>
              <Text type="danger">
                This item will work as a redirect, it will not open in an
                iframe!
              </Text>
            </Descriptions.Item>
          )}
          {finishValuesKeys.map((key) => {
            if (!finishValues[key]) return null;

            return (
              <Descriptions.Item label={key}>
                {getItemLabel({
                  key,
                  value: finishValues[key],
                  selectFormItem,
                })}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </>
    );
  };

  const confirm = () => {
    form
      .validateFields()
      .then((validataion) => {
        if (validataion?.errorFields) return;
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: getConfirmContent(),
          onOk: () => {
            onOk({ form, key: initialValues.key, type: modalData.type });
          },
          okText: "Confirm",
          cancelText: "Return to edit",
        });
      })
      .catch((error) => {});
  };

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={() => handleCancel(form)}
      okText={modalData.type === "CREATE_NEW" ? "Create" : "Update"}
      onOk={() => confirm()}
    >
      <Form
        name="Edit"
        layout="horizontal"
        form={form}
        onFinish={handleOnFinish}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Form.Item
          label="Label:"
          name="label"
          rules={[
            {
              required: true,
              message: "Please input your label!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type:"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select your page!",
            },
          ]}
        >
          <Select>
            <Select.Option value="PAGE">Page</Select.Option>
            <Select.Option value="SECTION">Section</Select.Option>
            <Select.Option value="ITEM">Item</Select.Option>
            <Select.Option value="SUB_ITEM">Sub Item</Select.Option>
            <Select.Option value="SECOND_SUB_ITEM">
              Second Sub Item
            </Select.Option>
            <Select.Option value="THIRD_SUB_ITEM">Third Sub Item</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Order:"
          name="order"
          rules={[
            {
              required: false,
              message: "Please input your Embed URL!",
            },
            { type: "number" },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Report ID:" name="reportID">
          <Input />
        </Form.Item>
        <Form.Item
          label="Embed Report URL:"
          name="embedUrl"
          rules={[
            {
              required: false,
              message: "Please input your Embed URL!",
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>

        <Form.Item
          label="Iframe:"
          name="iframe"
          rules={[
            {
              required: false,
              message: "Please input your label!",
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Basic report URL:"
          name="url"
          rules={[
            {
              required: false,
              message: "Please input your report url!",
            },
            {
              type: "url",
              warningOnly: true,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  if (getFieldValue("isRedirect")) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("The field isRedirect must be checked!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="isRedirect:"
          name="isRedirect"
          valuePropName="checked"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  if (getFieldValue("url")) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The field Base url must be filled!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Item have children:"
          name="haveChild"
          valuePropName="checked"
          disabled={
            initialValues.embedUrl || initialValues.iframe || initialValues.url
              ? true
              : false
          }
        >
          <Checkbox
            disabled={
              initialValues.embedUrl ||
              initialValues.iframe ||
              initialValues.url
                ? true
                : false
            }
          />
        </Form.Item>

        {selectFormItem && typeValue && typeValue !== "PAGE" && (
          <>
            <Form.Item
              label="Select page:"
              name="pageID"
              rules={[
                {
                  required: false,
                  message: "Please select your page!",
                },
              ]}
            >
              <Select>
                {selectFormItem?.pageID &&
                  selectFormItem.pageID.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {pageIDValue && typeValue && typeValue !== "SECTION" && (
              <Form.Item
                label="Select section:"
                name="sectionID"
                tooltip={{
                  title: "Select page",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: false,
                    message: "Please select your section!",
                  },
                ]}
              >
                <Select>
                  {selectFormItem?.sectionID &&
                    selectFormItem.sectionID
                      .filter(
                        (item) =>
                          item.pageID === pageIDValue &&
                          !item.url &&
                          !item.iframe
                      )
                      .map((item, key) => {
                        return (
                          <Select.Option
                            value={item.value}
                            key={item.value + key}
                          >
                            {item.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            )}
            {sectionIDValue && typeValue && typeValue !== "ITEM" && (
              <Form.Item
                label="Select item:"
                name="itemID"
                tooltip={{
                  title: "Select section please",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: false,
                    message: "Please select your item!",
                  },
                ]}
              >
                <Select>
                  {selectFormItem?.itemID &&
                    selectFormItem.itemID
                      .filter(
                        (item) =>
                          item.sectionID === sectionIDValue &&
                          item.pageID === pageIDValue &&
                          !item.url &&
                          !item.iframe
                      )
                      .map((item, key) => {
                        return (
                          <Select.Option
                            value={item.value}
                            key={item.value + key}
                          >
                            {item.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            )}
            {itemIDValue && typeValue && typeValue !== "SUB_ITEM" && (
              <Form.Item
                label="Select Subitem:"
                name="subitemID"
                tooltip={{
                  title: "Select subitem please",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: false,
                    message: "Please select your item!",
                  },
                ]}
              >
                <Select>
                  {selectFormItem?.subitemID &&
                    selectFormItem.subitemID
                      .filter(
                        (item) =>
                          item.sectionID === sectionIDValue &&
                          item.pageID === pageIDValue &&
                          item.itemID === itemIDValue &&
                          !item.url &&
                          !item.iframe
                      )
                      .map((item, key) => {
                        return (
                          <Select.Option
                            value={item.value}
                            key={item.value + key}
                          >
                            {item.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            )}
            {subitemIDValue && typeValue && typeValue !== "SECOND_SUB_ITEM" && (
              <Form.Item
                label="Select Second Subitem:"
                name="secondSubitemID"
                tooltip={{
                  title: "Select section please",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: false,
                    message: "Please select your item!",
                  },
                ]}
              >
                <Select>
                  {selectFormItem?.secondSubitemID &&
                    selectFormItem.secondSubitemID
                      .filter(
                        (item) =>
                          item.sectionID === sectionIDValue &&
                          item.pageID === pageIDValue &&
                          item.itemID === itemIDValue &&
                          item.subitemID === subitemIDValue &&
                          !item.url &&
                          !item.iframe
                      )
                      .map((item, key) => (
                        <Select.Option
                          value={item.value}
                          key={item.value + key}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
}

export default ModalForm;
