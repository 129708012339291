import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Space,
  Button as AntButton,
  Input,
  Checkbox,
  Form,
  Divider,
  message,
  Spin,
  Typography,
  Row,
  Col,
  Layout,
  Popconfirm,
} from "antd";
import ActionButtons from "../ActionButtons/ActionButton";

import { getUsers, updateUser, createUser } from "../../service/api";

import "antd/dist/antd.css";

const { Title, Text } = Typography;
const { Content } = Layout;
const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "label",
  },
  {
    title: "is Admin",
    dataIndex: "admin",
    key: "admin",
    width: "12%",
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "20%",
    key: "action",
  },
];

function EditUsers() {
  const [createUserForm] = Form.useForm();
  const [updatePassUserForm] = Form.useForm();
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [user, setUser] = useState({ email: null });

  const changePassword = () => {
    async function fetchChangePassword() {
      try {
        const res = await updateUser({
          data: {
            email: user.email,
            newPassword: user.newPassword,
          },
        });
        message.success(res.message);
        setIsOpenModal(false);
        updatePassUserForm.resetFields();
      } catch (error) {
        message.error(error.message);
      }
    }

    fetchChangePassword();
  };

  const handleCancel = () => {
    setUser({ email: null });
    updatePassUserForm.resetFields();
    setIsOpenModal(false);
  };

  const deleteUser = ({ email }) => {
    return async () => {
      try {
        const resultDeleteUser = await deleteUser({ email });
        message.success(resultDeleteUser.message || "User deleted!");
        const res = await getUsers();
        const formatedUsers = res.map((user) => ({
          ...user,
          admin: String(`${user.admin}`),
          key: user.id,
          action: (
            <ActionButtons
              onEdit={() => openModal(user.email)}
              onDelete={deleteUser({ email: user.email })}
            />
          ),
        }));

        setUsers(() => formatedUsers);
      } catch (error) {
        message.error(error.message);
      }
    };
  };

  const openModal = (email) => {
    setUser(() => ({ email: email }));
    setIsOpenModal(true);
  };

  const onFinishChangePassword = (values) => {
    setUser((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const res = await getUsers();
        const formatedUsers = res.map((user) => ({
          ...user,
          admin: String(`${user.admin}`),
          key: user.id,
          action: (
            <ActionButtons
              onEdit={() => openModal(user.email)}
              onDelete={deleteUser({ email: user.email })}
            />
          ),
        }));

        setUsers(() => formatedUsers);
        setIsLoading(false);
      } catch (error) {
        message.error(error.message);
      }
    }
    fetchUsers();
  }, []);

  const onFinish = async (values) => {
    try {
      await setIsLoading(true);
      const res = await createUser({ data: values });
      message.success(res.message);
      createUserForm.resetFields();
      const getNewUser = await getUsers();
      const formatedUsers = getNewUser.map((user) => ({
        ...user,
        admin: String(`${user.admin}`),
        key: user.id,
        action: (
          <ActionButtons
            onEdit={() => openModal(user.email)}
            onDelete={deleteUser({ email: user.email })}
          />
        ),
      }));

      setUsers(() => formatedUsers);
      setIsLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  if (!users)
    return (
      <Content>
        <Row align="middle" justify="center" style={{ height: "100%" }}>
          <Col>
            <Spin tip="Loading" size="large" />
          </Col>
        </Row>
      </Content>
    );

  return (
    <Content style={{ padding: "1rem", overflowY: "scroll" }}>
      <Space align="center" style={{ marginBottom: "1rem" }}>
        <h3>Create new user </h3>
        <Form
          name="CreateUser"
          layout="inline"
          onFinish={onFinish}
          form={createUserForm}
          style={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            minHeight: "80px",
            alignContent: "center",
          }}
          initialValues={{
            admin: false,
          }}
        >
          <Form.Item
            label="email:"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="password:"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="isAdmin:" name="admin" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item>
            <AntButton
              style={{
                margin: 0,
                padding: "4px 15px",
              }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </AntButton>
          </Form.Item>
        </Form>
      </Space>
      <Divider plain />
      <Table
        title={() => <h2>Users</h2>}
        pagination={{
          position: ["none", "none"],
        }}
        columns={columns}
        bordered
        loading={isLoading}
        dataSource={users}
      />
      <Modal
        open={isOpenModal}
        onCancel={handleCancel}
        okText="Save"
        onOk={changePassword}
        title={<Title>Changer password</Title>}
      >
        <Text>{`For user: ${user.email ? user.email : ""}`}</Text>
        <Form
          name="changeUserPassword"
          form={updatePassUserForm}
          onFinish={onFinishChangePassword}
          onValuesChange={onFinishChangePassword}
          style={{ marginTop: "16px" }}
        >
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
}

export default EditUsers;
