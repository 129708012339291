import React, { useEffect, useState } from "react";
import c from "./Image.module.css";

function Image({ src, title }) {
  const [imageTitle, setImageTitle] = useState("");
  useEffect(() => {
    if (title === "/supply-chain") {
      setImageTitle("Supply Chain");
    }
    if (title === "/finance") {
      setImageTitle("Finance");
    }
    if (title === "/quality") {
      setImageTitle("Quality");
    }
    if (title === "/dms-technology") {
      setImageTitle("DMS Technology");
    }
  }, [title]);

  return (
    <div className={c.ImageContainer}>
      <img
        className={c.Image}
        src={src}
        alt="Looking to some build on device"
      />
      {title && <h3 className={c.ImageTitle}>{imageTitle}</h3>}
    </div>
  );
}

export default Image;
