import React from "react";
import { Button, Form, Input, message } from "antd";

import useAuth from "../../hooks/useAuth";
import "./LoginPage.css";

import { login } from "../../service/api";

function LoginPage() {
  const { signin, user } = useAuth();

  const onFinish = (values) => {
    login({ ...values })
      .then((res) => {
        signin(res);
      })
      .catch((err) => {
        message(err.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };

  return (
    <main className="login-page-main">
      <h2 className="login-page-title">Login to customize</h2>
      <Form
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 20,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </main>
  );
}

export default LoginPage;
