import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "../service/api";
export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  let [user, setUser] = useState(localStorage.getItem("user"));
  const navigate = useNavigate();

  let signin = (newUser) => {
    setUser(true);
    localStorage.setItem("access_token", newUser.access_token);
    navigate("/editor/supply-chain");
    return;
  };

  let signout = () => {
    logout()
      .then((res) => {
        navigate("/login");
        setUser(false);
      })
      .catch((err) => {});
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
