import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Button, Tooltip } from "antd";

import useAuth from "../../hooks/useAuth";
import { LogoutOutlined } from "@ant-design/icons";
import c from "./Header.module.css";

import logo from "../../assets/images/getsitelogo.png";

const headerLink =
  process.env.REACT_APP_HEADER_LOGO_URL ||
  "https://jabil.sharepoint.com/sites/DMSAnalyticsPortal";

const activeStyle = {
  textDecoration: "underline",
  color: "white",
};

function Header() {
  const location = useLocation();
  const { signout } = useAuth();

  return (
    <header className={c.Header}>
      <a className={c.HeaderLink} href={headerLink}>
        <h1 className={c.HeaderTitle}>
          <img
            className={c.LogoImage}
            src={logo}
            width={60}
            alt="Company logo"
          />
          DMS Analytics Portal
        </h1>
      </a>
      {(location.pathname.search("editor") === 1 ||
        location.pathname.search("analytics") === 1 ||
        location.pathname.search("manage-users") === 1) && (
        <div>
          <NavLink
            to="/editor/supply-chain"
            className={c.NavLink}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Editor
          </NavLink>
          <NavLink
            to="/analytics/pages"
            className={c.NavLink}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Analytics
          </NavLink>
          <NavLink
            to="/manage-users"
            className={c.NavLink}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Manage Users
          </NavLink>

          <Tooltip title="Logout">
            <Button
              style={{}}
              ghost
              shape="circle"
              icon={<LogoutOutlined />}
              onClick={() => signout()}
            />
          </Tooltip>
        </div>
      )}
    </header>
  );
}

export default Header;
