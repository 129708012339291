export const getPageTitle = (type) => {
  switch (type) {
    case "PAGE":
      return "Edit page";
    case "SECTION":
      return "Edit section";
    case "ITEM":
      return "Edit item";
    case "SUB_ITEM":
      return "Edit sub-item";
    case "CREATE_NEW":
      return "Create new item";
    default:
      return "Edit page, section, item, sub-item";
  }
};
