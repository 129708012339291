import React from "react";
import { Button, Tooltip, Popconfirm } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

function ActionButtons({ label, type, onEdit, onDelete, ...props }) {
  return (
    <>
      <Tooltip title="Edit">
        <Button
          style={{ marginRight: "6px" }}
          icon={<EditFilled />}
          shape="circle"
          onClick={onEdit}
          {...props}
        />
      </Tooltip>
      <Tooltip title="Delete">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={onDelete}
          okText="Confirm"
          cancelText="Cancel"
        >
          <Button type="link" icon={<DeleteFilled />} shape="circle" href="#" />
        </Popconfirm>
      </Tooltip>
    </>
  );
}

export default ActionButtons;
