import React from "react";
import c from "./Button.module.css";

function Button({ handler = () => {}, label, style, ...props }) {
  return (
    <button styles={style} className={c.Button} onClick={handler} {...props}>
      {label}
    </button>
  );
}

export default Button;
