import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout as LayoutAntd } from "antd";
import Header from "../Header/Header";
import LoginPage from "../LoginPage/LoginPage";
import AdminPage from "../AdminPage/AdminPage";
import EditUsers from "../EditUsers/EditUsers";
import MainPage from "../MainPage/MainPage";
import AnalyticsPage from "../AnalyticsPage/AnalyticsPage";
import RequireAuth from "../RequireAuth/RequireAuth";

function Layout() {
  return (
    <LayoutAntd>
      <Header />
      <Routes>
        <Route index path="/login" element={<LoginPage />} />
        <Route
          index
          path="/editor/*"
          element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          }
        />
        <Route
          path="/manage-users"
          element={
            <RequireAuth>
              <EditUsers />
            </RequireAuth>
          }
        />
        <Route
          path="/analytics/*"
          element={
            <RequireAuth>
              <AnalyticsPage />
            </RequireAuth>
          }
        />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </LayoutAntd>
  );
}

export default Layout;
