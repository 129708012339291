import React from "react";
import Layout from "../components/Layout/Layout";
import { AuthProvider } from "../helpers/AuthProvider";

import "./App.css";

function App() {
  return (
    <AuthProvider>
      <Layout />
    </AuthProvider>
  );
}

export default App;
